import React from 'react';
import { connect } from 'react-redux';
import { PageLoading } from 'tyb';

const { appNameMap } = require('../mock/apps'); // 本地调试用

@connect(
  (state) => { 
    // return { allMenuUrls: state.ui.allMenuUrls }   // 原来的
    return { allMenuUrls: state.ui.allMenuUrls, permissions: state.ui.permissions }
  }
)
class Root extends React.Component {

  componentDidMount() {
    // this.props.history.replace('/promotionnormal')
    const { allMenuUrls, permissions } = this.props;
    if (allMenuUrls && permissions) {
      // this.props.history.replace(`/${allMenuUrls[0]}`);  
      // 登录后默认第一个菜单
      let theAppName = permissions.map(v => v.permission)[0]?.split('.')[0];
      for (const k in appNameMap) {
        if (k.split(',').includes(theAppName)) {
          theAppName = appNameMap[k];
        }
      }
      this.props.history.replace(`/${theAppName}/${allMenuUrls[0]}`);
    }
  }

  componentDidUpdate() {
    // const { allMenuUrls } = this.props;
    // if (allMenuUrls && allMenuUrls.length > 0) {
    //   console.log(allMenuUrls[0]);
    //   this.props.history.replace(`/${allMenuUrls[0]}`);
    // }
  }
  render() {
    const { allMenuUrls } = this.props;
    if (!allMenuUrls) {
      return (
        <PageLoading />
      )
    } 
    return (
      <div></div>
    )
  }
}

export default Root;